<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        type="flex"
        style="justify-content: flex-end"
      />
    </a-row>
    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="list"
    >
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: 'posts',
              params: {
                menu_id: item.id,
              },
            })
          "
        >
          {{ item.name }}
        </div>
      </template>
      <template slot="count" slot-scope="item">
        <strong>{{ item.post_count }}</strong> {{ $t("count") }}
      </template>
    </a-table>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

export default {
  data() {
    return {
      list: [],
      columns: [
        {
          title: this.$t("TagsColTitle"),
          key: "title",
          class: "wanted-fio-td",
          scopedSlots: {
            customRender: "name",
          },
        },
        {
          title: this.$t("postCount"),
          key: "count",
          fixed: "right",
          width: "100px",
          scopedSlots: { customRender: "count" },
        },
      ],
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      // this.loading = true;
      try {
        const { data } = await this.$store.dispatch("menu/fetch", {
          type: 1,
          parent_id: 5,
          category: "events"
        });
        data && this.$set(this, "list", data);
        // this.loading = false;
      } catch (err) {
        console.log(err);
      }
      // this.loading = false;
    },
  },
};
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
.ministry .ant-table-pagination.ant-pagination {
  display: none;
}
</style>
